<template>
  <div class="fk-input-container">
    <input
      :id="generateInputId(index)"
      :ref="generateInputId(index)"
      type="tel"
      maxlength="1"
      class="Medium no-bd-radius verifycode-input"
      :style="{
        fontWeight: fontWeight,
        fontSize: fkFontSize,
        borderBottom: fkInputColor(index),
        color: fontColor,
        width: fkWidth,
      }"
      v-model="inputValues[index]"
      contenteditable="true"
      :key="index"
      v-for="(input, index) in length"
    />
  </div>
</template>

<script>
export default {
  name: "vue-fake-input",
  props: {
    length: {
      type: Number,
      required: true,
    },
    fontSize: {
      type: Number,
      default: 22,
      required: false,
    },
    fontWeight: {
      type: String,
      default: "normal",
      required: false,
    },
    inputColor: {
      type: String,
      default: "#223b86",
      required: false,
    },
    fontColor: {
      type: String,
      default: "#444444",
      required: false,
    },
    allowPaste: {
      type: Boolean,
      default: true,
      required: false,
    },
    onlyNumber: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      inputValues: [],
    };
  },

  computed: {
    fkFontSize() {
      return `${this.fontSize}px`;
    },

    fkWidth() {
      let w = window.innerWidth;
      if (w > 600) {
        w = 600 - 40;
      }
      const width = (w - 40 - 10 * (this.length - 1)) / this.length;
      return `${width}px`;
    },
  },

  methods: {
    handleKeydown(event) {
      if (!this.onlyNumber) {
        return;
      }
      const key = event.charCode || event.keyCode || 0;
      if (!(key === 8 || key === 46 || key === 86 || key === 91 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105))) {
        event.preventDefault();
      }
    },

    generateInputId(index) {
      return `fk_${index + 1}`;
    },

    fkInputColor(index) {
      const color = this.inputValues[index] ? this.inputColor : "rgba(216, 216, 216, 0.8)";
      return `3px solid ${color}`;
    },

    handleInputFocus(index) {
      try {
        if (this.inputValues[index] && this.inputValues[index] !== "" && index < this.length - 1) {
          //   const nextInput = this.$refs[`fk_${index + 2}`];
          document.getElementById(`fk_${index + 2}`).focus();
          //   nextInput.focus();
        } else if (index > 0 && (!this.inputValues[index] || this.inputValues[index] === "")) {
          //   const previusInput = this.$refs[`fk_${index}`];
          document.getElementById(`fk_${index}`).focus();
          //   previusInput.focus();
        }
        this.returnFullString();
      } catch (e) {
        console.log(e);
      }
    },

    updateInputValue(index, value) {
      this.$set(this.inputValues, index, value);
    },

    returnFullString() {
      this.$emit("updateInput", this.inputValues.join(""));
    },
  },
  mounted() {
    this.inputValues = [];
    const inputElements = [...document.querySelectorAll("input.verifycode-input")];

    inputElements.forEach((ele, index) => {
      ele.addEventListener("keydown", e => {
        // if the keycode is backspace & the current field is empty
        // focus the input before the current. Then the event happens
        // which will clear the "before" input box.
        if (e.keyCode === 8 && e.target.value === "") inputElements[Math.max(0, index - 1)].focus();
      });
      ele.addEventListener("input", e => {
        // take the first character of the input
        // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
        // but I'm willing to overlook insane security code practices.
        const [first, ...rest] = e.target.value;
        e.target.value = first ?? ""; // first will be undefined when backspace was entered, so set the input to ""
        const lastInputBox = index === inputElements.length - 1;
        const insertedContent = first !== undefined;
        if (insertedContent && !lastInputBox) {
          // continue to input the rest of the string
          inputElements[index + 1].focus();
          inputElements[index + 1].value = rest.join("");
          inputElements[index + 1].dispatchEvent(new Event("input"));
        }
        this.returnFullString();
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.fk-input-container {
  display: inline-flex;

  input {
    border: none;
    margin-left: 10px;
    text-align: center;

    &:first-child {
      margin-left: 0;
    }
    &:focus {
      outline: none;
    }
  }
}
</style>
