<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar
        :bigtitle="true"
        :title="$t('verify.VerificationCode')"
        :noboder="$store.state.isPC ? true : false"
        :showBack="true"
      ></topbar>

      <div class="pc-box big">
        <div class="pc-min-content">
          <div class="content">
            <div class="title-PC" v-if="$store.state.isPC">
              {{ $t("verify.VerificationCode") }}
            </div>
            <div class="pad-t-14">
              <label
                class="main-color font-only-18"
                style="line-height: 28px; letter-spacing: 0.4px"
              >
                {{ $t("verify.PleaseInput") }}
                <span v-if="$route.params.registerType == 2">
                  {{ phone }}
                </span>
                <span v-if="$route.params.registerType == 1">{{ email }}</span>
              </label>
            </div>
            <div class="code-input">
              <vue-fake-input
                :length="6"
                :fontSize="48"
                :fontWeight="'bold'"
                @updateInput="updateInput"
                v-model="fkValue"
              />
              <!-- <ion-input
                type="text"
                :maxlength='6'
                class="Medium verifyCode-input"
                v-model="VerifyCode"
              /> -->
            </div>
            <div class="pad-t-50">
              <ion-button
                :disabled="VerifyCode.length != 6"
                strong
                expand="block"
                @click="verifyEvent"
                >{{ $t("verify.Verify") }}</ion-button
              >
            </div>
            <div
              class="btn-plain btn-resend margin-t-30"
              :class="{ disabled: showTime }"
              @click="reSendSmsEvent"
            >
              <span class="font-16-bold">{{ $t("verify.Resend") }} </span
              ><span v-show="showTime"
                >(<van-count-down
                  :time="time"
                  ref="countDown"
                  format="ss"
                  @finish="finish"
                />s)</span
              >
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import VueFakeInput from "@/components/VueFakeInput.vue";
import config from "../config";
export default {
  name: "VerificationCode",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, IonButton, topbar, VueFakeInput },
  data() {
    return {
      isEmail: false,
      VerifyCode: "",
      time: 60 * 1000,
      showTime: true,
      phone: "",
      email: "",
      canSendS: true,
      canSendE: true,
      canVerifyS: true,
      canVerifyE: true,
      fkValue: "",
    };
  },
  methods: {
    verifyEvent() {
      if (this.$route.params.registerType == 1) {
        this.sendGAEvent("Click", "Verification Code", "Email");

        this.emailVerify();
      } else if (this.$route.params.registerType == 2) {
        this.sendGAEvent("Click", "Verification Code", "Phone");
        this.smsVerify();
      }
    },
    async smsVerify() {
      if (!this.canVerifyS) return;
      this.canVerifyS = false;
      const objdata = this.getAuthObj();
      objdata.phone = this.phone;
      objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      if (this.$route.params.type == 1) {
        objdata.apiUrl = this.apiConfig.verifySmsForReg;
      } else {
        objdata.apiUrl = this.apiConfig.verifySms;
      }

      objdata.verifyCode = this.VerifyCode;
      objdata.verifyType = this.$route.params.type;
      if (this.$store.state.needVerify) {
        objdata.verifyType = 5;
      }
      const data = await this.$store.dispatch("apiPutEvent", objdata);
      setTimeout(() => {
        this.canVerifyS = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.nextEvent();
    },
    async emailVerify() {
      if (!this.canVerifyE) return;
      this.canVerifyE = false;
      const objdata = this.getAuthObj();
      objdata.email = this.email.trim().toLowerCase();
      objdata.apiUrl = this.apiConfig.verifyEmail;
      objdata.verifyCode = this.VerifyCode;
      objdata.verifyType = this.$route.params.type;
      if (this.$store.state.needVerify) {
        objdata.verifyType = 5;
      }
      const data = await this.$store.dispatch("apiPutEvent", objdata);
      setTimeout(() => {
        this.canVerifyE = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.nextEvent();
    },
    async sendSmsEvent() {
      if (!this.canSendS) return;
      this.canSendS = false;
      const objdata = this.getAuthObj();
      objdata.phone = this.phone;
      objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      objdata.apiUrl = this.apiConfig.sendSms;
      objdata.verifyType = this.$route.params.type;
      if (this.$store.state.needVerify) {
        objdata.verifyType = 5;
      }
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendS = true;
      }, 1500);
      if (!data) {
        return;
      }

      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.resendSuccess();
    },
    async sendEmailEvent() {
      if (!this.canSendE) return;
      this.canSendE = false;
      const objdata = this.getAuthObj();
      objdata.email = this.email.trim().toLowerCase();
      objdata.apiUrl = this.apiConfig.sendVerifyEmail;
      objdata.verifyType = this.$route.params.type;
      if (this.$store.state.needVerify) {
        objdata.verifyType = 5;
      }
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendE = true;
      }, 1500);
      if (!data) {
        return;
      }

      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.resendSuccess();
    },
    nextEvent() {
      if (this.$route.params.type == 1) {
        if (this.$route.params.registerType == 1) {
          this.$store.state.userInfo.emailVerifyCode = this.VerifyCode;
        } else if (this.$route.params.registerType == 2) {
          this.$store.state.userInfo.smsVerifyCode = this.VerifyCode;
        }
        this.$router.replace({
          path: "/createAccount",
        });
      } else if (this.$route.params.type == 4) {
        if (this.$route.params.registerType == 1) {
          this.$store.state.userInfo.emailVerifyCode = this.VerifyCode;
        } else if (this.$route.params.registerType == 2) {
          this.$store.state.userInfo.smsVerifyCode = this.VerifyCode;
        }
        this.$router.replace({
          path: "/resetPassword",
        });
      } else if (this.$route.params.type == 3) {
        if (this.$route.params.registerType == 1) {
          if (this.$store.state.form.profileVerifyType == "setProfile") {
            // setProfile
            this.$store.state.userInfo.email = this.$store.state.form.email
              .trim()
              .toLowerCase();
            this.$store.state.userInfo.emailVerifyCode = this.VerifyCode;
            this.$store.state.form.isEmailValidation = 1;
          } else {
            // editProfile
            this.$store.state.form.userInfo.email = this.$store.state.form.email
              .trim()
              .toLowerCase();
            this.$store.state.form.userInfo.emailVerifyCode = this.VerifyCode;
            this.$store.state.form.userInfo.isEmailValidation = 1;
          }
        } else if (this.$route.params.registerType == 2) {
          if (this.$store.state.form.profileVerifyType == "setProfile") {
            // setProfile
            this.$store.state.userInfo.phone = this.$store.state.form.phone;
            this.$store.state.userInfo.smsVerifyCode = this.VerifyCode;
            this.$store.state.form.isPhoneValidation = 1;
          } else {
            // editProfile
            this.$store.state.form.userInfo.phone = this.$store.state.form.phone;
            this.$store.state.form.userInfo.smsVerifyCode = this.VerifyCode;
            this.$store.state.form.userInfo.isPhoneValidation = 1;
          }
        }
        this.$router.go(-1);
      } else if (this.$route.params.type == 5) {
        this.$router.go(-1);
      }
    },
    resendSuccess() {
      this.time = 60 * 1000;
      this.showTime = true;
      this.$refs.countDown.reset();
    },
    finish() {
      this.showTime = false;
    },
    async reSendSmsEvent() {
      if (this.showTime) {
        return;
      }
      if (this.$route.params.registerType == 1) {
        this.sendGAEvent("Click", "Verification Code", "Resend Email");
        this.sendEmailEvent();
      } else if (this.$route.params.registerType == 2) {
        this.sendGAEvent("Click", "Verification Code", "Resend SMS");
        this.sendSmsEvent();
      }
    },
    updateInput(data) {
      this.VerifyCode = data;
    },
  },
  ionViewWillEnter() {
    this.VerifyCode = "";
    this.canSendS = true;
    this.canSendE = true;
    this.canVerifyS = true;
    this.canVerifyE = true;
    if (this.$route.params.type == 3) {
      this.phone = this.$store.state.form.confirmPhone
        ? this.$store.state.form.confirmPhone
        : this.$store.state.form.phone;
      this.email = this.$store.state.form.confirmEmail
        ? this.$store.state.form.confirmEmail
        : this.$store.state.form.email;
    } else {
      this.phone = this.$store.state.userInfo.phone;
      this.email = this.$store.state.userInfo.email;
    }
    this.$store.state.exitApp = false;
    this.sendGAPageView("Verification Code");
  },
};
</script>
